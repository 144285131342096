import { Form } from "antd";
import React, { useEffect } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebSiteFooterContentRequest,
  postWebSiteFooterContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";

const WebSiteFooter = () => {
  const dispatch = useDispatch();
  const { webSiteFooterData, webSiteFooterStatus, webSiteFooterError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getWebSiteFooterContentRequest(CONTENT_PAGES.WEBSITE_FOOTER));
  }, [dispatch]);

  useEffect(() => {
    if (webSiteFooterData) {
      form.setFieldsValue({
        "detail[footerSection][description]": webSiteFooterData?.contentPage?.detail?.footerSection?.description,
        "detail[footerSection][rightsReserved]": webSiteFooterData?.contentPage?.detail?.footerSection?.rightsReserved,
        "detail[footerSection][facebookURL]": webSiteFooterData?.contentPage?.detail?.footerSection?.facebookURL,
        "detail[footerSection][linkedInURL]": webSiteFooterData?.contentPage?.detail?.footerSection?.linkedInURL,
        "detail[footerSection][twitterURL]": webSiteFooterData?.contentPage?.detail?.footerSection?.twitterURL,
        "detail[footerSection][instagramURL]": webSiteFooterData?.contentPage?.detail?.footerSection?.instagramURL,
        "detail[footerSection][youtubeURL]": webSiteFooterData?.contentPage?.detail?.footerSection?.youtubeURL,
        "detail[footerSection][locumTenensHeading]": webSiteFooterData?.contentPage?.detail?.footerSection?.locumTenensHeading,
        "detail[footerSection][aboutHeading]": webSiteFooterData?.contentPage?.detail?.footerSection?.aboutHeading,
        "detail[footerSection][contactUsHeading]": webSiteFooterData?.contentPage?.detail?.footerSection?.contactUsHeading,
        "detail[footerSection][emailAddress]": webSiteFooterData?.contentPage?.detail?.footerSection?.emailAddress,
        "detail[footerSection][phone]": webSiteFooterData?.contentPage?.detail?.footerSection?.phone,
        "detail[footerSection][address]": webSiteFooterData?.contentPage?.detail?.footerSection?.address,
      });
    }
  }, [webSiteFooterData]);

  const onUpdate = (values) => {
    const data = {
      ...values,
      name: 'Web Site Footer',
      _method: 'PUT'
    }
    dispatch(postWebSiteFooterContentRequest({ data, id: webSiteFooterData?.contentPage?.id }))
  };
  return (
    <>
      {webSiteFooterStatus === STATUSES.LOADING ? (
        <Loading />
      ) : webSiteFooterError === STATUSES.ERROR ? (
        <div>{webSiteFooterError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Web Site Footer</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    <TextAreas
                      labelText="Footer Section Description"
                      name="detail[footerSection][description]"
                      placeText="Footer Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Rights Reserved Text"
                      name="detail[footerSection][rightsReserved]"
                      placeText="Footer Section Rights Reserved Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Facebook URL"
                      name="detail[footerSection][facebookURL]"
                      placeText="Footer Section Facebook URL"
                      isUrl={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section LinkedIn URL"
                      name="detail[footerSection][linkedInURL]"
                      placeText="Footer Section LinkedIn URL"
                      isUrl={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Twitter URL"
                      name="detail[footerSection][twitterURL]"
                      placeText="Footer Section Twitter URL"
                      isUrl={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Instagram URL"
                      name="detail[footerSection][instagramURL]"
                      placeText="Footer Section Instagram URL"
                      isUrl={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section YouTube URL"
                      name="detail[footerSection][youtubeURL]"
                      placeText="Footer Section YouTube URL"
                      isUrl={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Locum Tenens Heading"
                      name="detail[footerSection][locumTenensHeading]"
                      placeText="Footer Section Locum Tenens Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section About Heading"
                      name="detail[footerSection][aboutHeading]"
                      placeText="Footer Section About Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Contact Us Heading"
                      name="detail[footerSection][contactUsHeading]"
                      placeText="Footer Section Contact Us Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Email Address"
                      name="detail[footerSection][emailAddress]"
                      placeText="Footer Section Email Address"
                      isEmail={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Phone"
                      name="detail[footerSection][phone]"
                      placeText="+1 (123) 123-1234"
                    // isPhone={true}
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Footer Section Address"
                      name="detail[footerSection][address]"
                      placeText="520 West Lacey Blvd, Hanford, CA 93230"
                      isAddress={true}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default WebSiteFooter;
