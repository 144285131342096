import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { FEATURE_DISCIPLINE_UPDATE_API, FEATURE_JOB_UPDATE_API, GET_HOSPITAL_TYPE_API, GET_LOCATION_AGAINST_HOSPITAL_TYPE_API, GET_REFERRALS_LIST_API, GET_SYSTEM_CONFIGURATION_DETAIL_API, JOB_STATUS_UPDATE_API, REFERRAL_JOB_UPDATE_API, URGENT_HIRING_JOB_UPDATE_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getDisciplineListRequest = createAsyncThunk(
    "appDataSlice/getDisciplineListRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                {
                    "model_path": "\\App\\Models\\Discipline",
                    "model_name": "Discipline",
                    "status": "1",
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getStateListRequest = createAsyncThunk(
    "appDataSlice/getStateListRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                {
                    "model_path": "\\App\\Models\\State",
                    "model_name": "State",
                    "status": "1",
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getHospitalTypeListRequest = createAsyncThunk(
    "appDataSlice/getHospitalTypeListRequest",
    async ({ state }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_HOSPITAL_TYPE_API,
                REQUEST_METHOD.POST,
                {
                    "state_id": state,
                    "status": "1",
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getLocationListRequest = createAsyncThunk(
    "appDataSlice/getLocationListRequest",
    async ({ state, hospitalType }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_LOCATION_AGAINST_HOSPITAL_TYPE_API,
                REQUEST_METHOD.POST,
                {
                    "state_id": state,
                    "hospital_type_id": hospitalType,
                    "status": "1",
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getReferralListRequest = createAsyncThunk(
    "appDataSlice/getReferralListRequest",
    async ({ generalSearch, page, pageSize, discipline, state, hospitalType, location, dateRange, }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_REFERRALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": {
                        "status": 1,
                        "generalSearch": generalSearch,
                        "discipline": discipline ?? "",
                        "state": state ?? "",
                        "hospitalType": hospitalType ?? "",
                        "location": location ?? "",
                        "dateRange": dateRange ?? ""
                    }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getReferralListExportRequest = createAsyncThunk(
    "appDataSlice/getReferralListExportRequest",
    async ({ generalSearch, discipline, state, hospitalType, location, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_REFERRALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": {
                        "status": 1,
                        "generalSearch": generalSearch,
                        "discipline": discipline ?? "",
                        "state": state ?? "",
                        "hospitalType": hospitalType ?? "",
                        "location": location ?? "",
                        "dateRange": dateRange ?? ""
                    }
                },
                true,
            );
            if (response.status === 200) {

                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const jobFeatureChangeRequest =
    async ({ jobId, isFeatured }) => {
        try {
            const response = await apiService(
                FEATURE_JOB_UPDATE_API,
                REQUEST_METHOD.PATCH,
                {
                    "job_id": jobId,
                    "is_featured": isFeatured ? 1 : 0,
                },
                true,
            );
            if (response.status === 200) {
                return successNotification(response.data.message);
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
        }
    };

export const jobReferralChangeRequest =
    async ({ jobId, isReferral }) => {
        try {
            const response = await apiService(
                REFERRAL_JOB_UPDATE_API,
                REQUEST_METHOD.PATCH,
                {
                    "job_id": jobId,
                    "is_referral": isReferral ? 1 : 0,
                },
                true,
            );
            if (response.status === 200) {
                return successNotification(response.data.message);
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
        }
    };
export const jobUrgentHiringChangeRequest =
    async ({ jobId, urgentHiring }) => {
        try {
            const response = await apiService(
                URGENT_HIRING_JOB_UPDATE_API,
                REQUEST_METHOD.PATCH,
                {
                    "job_id": jobId,
                    "is_urgent_hiring": urgentHiring ? 1 : 0,
                },
                true,
            );
            if (response.status === 200) {
                return successNotification(response.data.message);
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
        }
    };
export const jobStatusChangeRequest =
    async ({ jobId, status }) => {
        try {
            const response = await apiService(
                JOB_STATUS_UPDATE_API,
                REQUEST_METHOD.PATCH,
                {
                    "job_id": jobId,
                    "status": status ? 1 : 0,
                },
                true,
            );
            if (response.status === 200) {
                return successNotification(response.data.message);
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
        }
    };

export const disciplineFeatureChangeRequest =
    async ({ disciplineId, isFeatured }) => {
        try {
            const response = await apiService(
                FEATURE_DISCIPLINE_UPDATE_API,
                REQUEST_METHOD.PATCH,
                {
                    "discipline_id": disciplineId,
                    "is_featured": isFeatured ? 1 : 0,
                },
                true,
            );
            if (response.status === 200) {
                return successNotification(response.data.message);
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
        }
    };


const appDataSlice = createSlice({
    name: "appData",
    initialState: {
        DisciplineData: null,
        DisciplineStatus: STATUSES.IDLE,
        DisciplineError: null,

        StateData: null,
        StateStatus: STATUSES.IDLE,
        StateError: null,

        HospitalTypeData: null,
        HospitalTypeStatus: STATUSES.IDLE,
        HospitalTypeError: null,

        LocationData: null,
        LocationStatus: STATUSES.IDLE,
        LocationError: null,

        ReferralData: null,
        ReferralStatus: STATUSES.IDLE,
        ReferralError: null,

    },
    extraReducers: {
        // Discipline List Requests
        [getDisciplineListRequest.pending]: (state, action) => {
            state.DisciplineStatus = STATUSES.LOADING;
        },
        [getDisciplineListRequest.fulfilled]: (state, action) => {
            state.DisciplineStatus = STATUSES.IDLE;
            state.DisciplineData = action.payload;
        },
        [getDisciplineListRequest.rejected]: (state, action) => {
            state.DisciplineStatus = STATUSES.ERROR;
            state.DisciplineError = action.error;
        },
        // State List Requests
        [getStateListRequest.pending]: (state, action) => {
            state.StateStatus = STATUSES.LOADING;
        },
        [getStateListRequest.fulfilled]: (state, action) => {
            state.StateStatus = STATUSES.IDLE;
            state.StateData = action.payload;
        },
        [getStateListRequest.rejected]: (state, action) => {
            state.StateStatus = STATUSES.ERROR;
            state.StateError = action.error;
        },
        // Hospital Type List Requests
        [getHospitalTypeListRequest.pending]: (state, action) => {
            state.HospitalTypeStatus = STATUSES.LOADING;
        },
        [getHospitalTypeListRequest.fulfilled]: (state, action) => {
            state.HospitalTypeStatus = STATUSES.IDLE;
            state.HospitalTypeData = action.payload;
        },
        [getHospitalTypeListRequest.rejected]: (state, action) => {
            state.HospitalTypeStatus = STATUSES.ERROR;
            state.HospitalTypeError = action.error;
        },
        // Location List Requests
        [getLocationListRequest.pending]: (state, action) => {
            state.LocationStatus = STATUSES.LOADING;
        },
        [getLocationListRequest.fulfilled]: (state, action) => {
            state.LocationStatus = STATUSES.IDLE;
            state.LocationData = action.payload;
        },
        [getLocationListRequest.rejected]: (state, action) => {
            state.LocationStatus = STATUSES.ERROR;
            state.LocationError = action.error;
        },
        // Referral List Requests
        [getReferralListRequest.pending]: (state, action) => {
            state.ReferralStatus = STATUSES.LOADING;
        },
        [getReferralListRequest.fulfilled]: (state, action) => {
            state.ReferralStatus = STATUSES.IDLE;
            state.ReferralData = action.payload;
        },
        [getReferralListRequest.rejected]: (state, action) => {
            state.ReferralStatus = STATUSES.ERROR;
            state.ReferralError = action.error;
        },
    },

});

export default appDataSlice.reducer;

