import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../components/TextEditor/TextEditor";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import { editConfigurationalDisciplineRequest, getConfigurationalDisciplineListRequest } from "../../../redux/systemConfigurationSlice";
import { STATUSES } from "../../../config/constants/defaultValues";
import { useDispatch } from "react-redux";
import SeoSection from "../../../components/shared/seoSection";

const DisciplineEditModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [isEditorEmpty, setIsEditorEmpty] = useState(false);

    const [requirementEditorData, setRequirementEditorData] = useState();
    const [requirementEditorEdit, setRequirementEditorEdit] = useState();

    const [benefitsEditorData, setBenefitsEditorData] = useState();
    const [benefitsEditorEdit, setBenefitsEditorEdit] = useState();

    const [descriptionEditorData, setDescriptionEditorData] = useState();
    const [descriptionEditorEdit, setDescriptionEditorEdit] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [detailEditorData, setDetailEditorData] = useState();
    const [detailEditorEdit, setDetailEditorEdit] = useState();

    //Seo Section states
    const [ogImageUrl, setOGImageUrl] = useState();
    const [ogImageFile, setOGImageFile] = useState();
    const [twitterImageUrl, setTwitterImageUrl] = useState();
    const [twitterImageFile, setTwitterImageFile] = useState();



    useEffect(() => {
        if (modalData) {
            form.setFieldsValue({
                name: modalData?.name,
                heading: modalData?.heading,
                // seo fields data
                meta_title: modalData?.seo_keywords?.meta_title,
                meta_description: modalData?.seo_keywords?.meta_description,
                is_canonical: modalData?.seo_keywords?.is_canonical,
                robots_meta: modalData?.seo_keywords?.robots_meta,
                meta_author: modalData?.seo_keywords?.meta_author,
                og_type: modalData?.seo_keywords?.og_type,
                og_title: modalData?.seo_keywords?.og_title,
                og_description: modalData?.seo_keywords?.og_description,
                og_image_width: modalData?.seo_keywords?.og_image_width,
                og_image_height: modalData?.seo_keywords?.og_image_height,
                og_url: modalData?.seo_keywords?.og_url,
                og_site_name: modalData?.seo_keywords?.og_site_name,
                og_locale: modalData?.seo_keywords?.og_locale,
                og_locale_alternate: modalData?.seo_keywords?.og_locale_alternate,
                twitter_card: modalData?.seo_keywords?.twitter_card,
                twitter_site: modalData?.seo_keywords?.twitter_site,
                twitter_title: modalData?.seo_keywords?.twitter_title,
                twitter_description: modalData?.seo_keywords?.twitter_description,
                twitter_url: modalData?.seo_keywords?.twitter_url,
            });
            setRequirementEditorEdit(modalData?.requirements ?? "")
            setBenefitsEditorEdit(modalData?.benefits ?? "")
            setDescriptionEditorEdit(modalData?.description ?? "")
            setDetailEditorEdit(modalData?.detail ?? "")
            // seo Data

            setOGImageUrl(modalData?.seo_keywords?.og_image);
            setTwitterImageUrl(modalData?.seo_keywords?.twitter_image);
            setRequirementEditorEdit(modalData?.requirements ?? modalData?.discipline?.requirements)
            setBenefitsEditorEdit(modalData?.benefits ?? modalData?.discipline?.benefits)
            setDescriptionEditorEdit(modalData?.description ?? modalData?.discipline?.description)
        }

    }, [modalData])

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    //Save Button
    const onSave = async (formData) => {
        var values = {
            ...formData,
            id: modalData?.id,
            discipline_id: modalData?.id,
            detail: detailEditorData,
            requirements: requirementEditorData,
            description: descriptionEditorData,
            benefits: benefitsEditorData,
            "og_image": ogImageFile ?? "",
            "twitter_image": twitterImageFile ?? "",
            "is_canonical": formData.is_canonical === true ? 1 : 0,
            status: "1",
            _method: 'PUT'
        }
        setIsLoading(true);
        await dispatch(editConfigurationalDisciplineRequest({
            values
        })).then((data) => {
            if (data.payload.status === 200) {
                dispatch(getConfigurationalDisciplineListRequest({}));
                setIsModalOpen(false);
            }
            setIsLoading(false);

        })
    }
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Edit Discipline</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <Form layout="vertical" className="pt-3" onFinish={onSave} form={form}>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-between align-items-start">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <BaseInput
                                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                        labelText="Discipline"
                                        name="name"
                                        placeText="Add Discipline"
                                    />
                                </div>
                                <SaveButton loading={isLoading ? STATUSES.LOADING : false} title="Save" />
                            </div>
                        </div>

                        <div class="row">
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <BaseInput
                                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                    labelText="Page Heading"
                                    name="heading"
                                    placeText="Add Heading"
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Page Description"
                                    setEditorData={setDetailEditorData}
                                    editorEditData={detailEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Job Description"
                                    setEditorData={setDescriptionEditorData}
                                    editorEditData={descriptionEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Requirements"
                                    setEditorData={setRequirementEditorData}
                                    editorEditData={requirementEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Benefits"
                                    setEditorData={setBenefitsEditorData}
                                    editorEditData={benefitsEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <SeoSection ogImageUrl={ogImageUrl}
                                setOGImageFile={setOGImageFile}
                                setOGImageUrl={setOGImageUrl}
                                twitterImageUrl={twitterImageUrl}
                                setTwitterImageFile={setTwitterImageFile}
                                setTwitterImageUrl={setTwitterImageUrl} />
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default DisciplineEditModal;
